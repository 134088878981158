import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import { StorageKeys, StorageUtility } from '../StorageUtility';

const LoginPage = () => {
  const navigator = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    Amplify.configure({
      aws_cognito_region: 'ap-south-1',
      aws_user_pools_id: 'ap-south-1_pnlZF3yi2',
      aws_user_pools_web_client_id: '30ftrql3tnr04tbdofuouugnbr'
    });
    Auth.configure({
      aws_cognito_region: 'ap-south-1',
      aws_user_pools_id: 'ap-south-1_pnlZF3yi2',
      aws_user_pools_web_client_id: '30ftrql3tnr04tbdofuouugnbr'
    });
  }, []);

  const handleLogin = async () => {
    Auth.signIn(email, password)
      .then((resUser) => {
        console.log(resUser);
        if (resUser?.signInUserSession?.idToken?.jwtToken) {
          const sessiontoken = resUser?.signInUserSession.idToken.jwtToken ?? '';
          localStorage.setItem('token', sessiontoken);
          StorageUtility.setItem(StorageKeys.SESSION_TOKEN, sessiontoken);
        }
        const userEmail = resUser?.attributes?.email ?? email;

        navigator.push('/');
      })
      .catch((err) => {
        console.log('====================================');
        console.log(err);
        console.log('====================================');
        // reject(err);
      });
    navigator.push('/');
  };

  return (
    <div className="h-screen flex flex-col text-center items-center justify-center bg-gray-background">
      {/* Title */}
      <div className="text-center flex flex-col gap-4 mb-20 font-semibold">
        <label className="text-6xl text-bold text-center items-center">Sign in to playground</label>
      </div>

      <div className="p-8 rounded-md shadow-2xl-primary-dark w-128 items-center align-middle bg-white">
        {/*  Login */}
        <div className="flex flex-col gap-2  items-center">
          <input
            type="email"
            required
            placeholder="parmar@vegapay.tech"
            className="rounded-md p-2 border-primary-dark border w-full"
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="password"
            placeholder="Password"
            required
            className="rounded-md p-2 border-primary-dark border w-full"
            onChange={(e) => setPassword(e.target.value)}
          />

          <button
            className="text-white px-5 py-2 rounded-md bg-gradient-to-r from-primary-dark to-primary-light mt-4 w-full"
            onClick={() => handleLogin()}>
            Login
          </button>
        </div>
      </div>
      {/* <label className="px-5 py-2 rounded-md mt-4 w-full" onClick={() => handleLogin()}>
        Don't have an account?{' '}
        <Link to={{ pathname: '/signup' }} className="text-primary-dark">
          Sign up instead
        </Link>
      </label> */}
    </div>
  );
};

export default LoginPage;
