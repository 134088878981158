/* eslint-disable no-undef */
/** @format */

import axios from 'axios';
import { StorageKeys, StorageUtility } from './StorageUtility';
import { AuthService } from './services/AuthService';

const ALPHA_API_GATEWAY = 'https://api.unity-uat.vegapay.tech';

export const API_GATEWAY = ALPHA_API_GATEWAY;
export const configureAxiosDefaults = () => {
  const BASE_URL = API_GATEWAY;
  axios.defaults.baseURL = BASE_URL;
  axios.interceptors.request.use(
    (config: any) => {
      config.headers['Authorization'] = `Bearer ${StorageUtility.getItem(
        StorageKeys.SESSION_TOKEN
      )}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // axios.interceptors.response.use(
  //   (response) => response,
  //   async (error) => {
  //     const originalRequest = error.config;

  //     if (error.response?.status === 403 && !originalRequest.retry) {
  //       originalRequest.retry = true;

  //       const newSessionToken = await fetchNewSessionToken();

  //       // Update the authorization header and retry the request
  //       originalRequest.headers.Authorization = `Bearer ${newSessionToken}`;
  //       return axios(originalRequest);
  //     }

  //     return Promise.reject(error);
  //   }
  // );
};

export const fetchNewSessionToken = async (): Promise<string> => {
  const response = await AuthService.generateClientAccessToken();
  const newToken = response.accessToken;
  StorageUtility.setItem(StorageKeys.SESSION_TOKEN, newToken);
  return newToken;
};

//request interceptor

// response interceptor
// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error.response.status == 401) {
//       // localStorage.clear();
//       // window.location.href = '/login';
//     }
//     return Promise.reject(error);
//   }
// );
