import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Task } from '../types/task';
import { fetchNewSessionToken } from '../axios_config';
import { StorageKeys, StorageUtility } from '../StorageUtility';

const DashboardPage = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const navigator = useHistory();

  const handleCreateTask = () => {
    navigator.push('/define-task');
  };

  useEffect(() => {
    const abortController = new AbortController();
    const sessionToken = StorageUtility.getItem(StorageKeys.SESSION_TOKEN);
    if (sessionToken != null && sessionToken != undefined) {
      setTimeout(() => {
        getTasks();
      }, 100);
    } else {
      navigator.replace('/login');
    }
    return () => abortController.abort();
  }, []);

  const getTasks = async () => {
    // await fetchNewSessionToken();
    axios
      .get('/los/v3/api-playground/task')
      .then(function (response) {
        console.log(tasks);

        const data: Task[] = response.data as Task[];
        setTasks([...tasks, ...data]);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          if (
            error.response.status === 401 ||
            error.response.status === 403 ||
            error.response?.data?.errorCode === 'VGP_TOKEN_1000'
          ) {
            console.log('Unauthorized access - 401 error. Logging out');
            StorageUtility.clear();
            navigator.replace('/login');
          } else {
            console.log('Error:', error.response.status, error.response.data);
          }
        } else {
          console.log('Error:', error.message);
        }
      });
  };

  return (
    <div className="p-10 min-w-1/2">
      {/*  Heading */}
      <div className="mb-10">
        <label className="font-bold text-5xl">Dashboard</label>
      </div>

      {/* Tasks  */}
      <div className="">
        {tasks.map((item) => (
          <Link
            to={{
              pathname: '/task-detail',
              state: {
                task: item
              },
              search: 'id=' + item.id
            }}
            key={item.id}>
            <div
              className="p-2 mb-2 border
             border-primary-light
              rounded-md 
              w-3/4 
             hover:bg-primary-dark
             hover:text-white
             hover:border-white
             flex
             flex-col
               ">
              <label className="font-semibold text-xl">{item.name}</label>
              <label className="text-sm text-opacity-50">{item.description}</label>
            </div>
          </Link>
        ))}
      </div>

      {/* Empty page */}
      {tasks.length == 0 && (
        <div className="items-center  text-center">
          <div className="inline-block ">
            <div className="items-left flex flex-col gap-2 w-fit p-10 ">
              {/* <div className="inline-block h-72 w-72">
                <img src={empty}></img>
              </div> */}

              <label className=" font-bold text-2xl text-gray">Loading....! </label>
              {/* <label className=" font-bold text-2xl text-gray">There are no tasks here! </label> */}
              {/* <label className=" font-semibold text-gray">Start by creating one</label> */}
              {/* <button
                className="bg-primary-dark px-4 py-1 mt-2 rounded shadow-md text-white"
                onClick={handleCreateTask}>
                Create Task
              </button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardPage;
